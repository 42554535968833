'use strict';

angular.module('mvpcarrefourApp')
  .factory('Social', ['$rootScope', '$location', function($rootScope, $location) {

    function setMetaTags(pageData) {
      if (pageData) {
        $rootScope.metaTags = {
          title: _.has(pageData, 'title') ? pageData.title : null,
          description: _.has(pageData, 'description') ? pageData.description : null,
          url: $location.$$absUrl,
          image: _.has(pageData, 'image') ? pageData.image : null
        };
      } else {
        $rootScope.metaTags = {};
      }
    }
    return {
      setMetaTags: setMetaTags
    }
  }]);
